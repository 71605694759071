@import '../../styles/variables.scss';

.contactNumber {
  display: flex;
  align-items: center;
}

.phoneIcon {
  width: 14px;
  margin-right: 17px;
  fill: $color-firm5;
}
