@import '../../styles/mixins.scss';

%basicLinkStyles {
  position: relative;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  transition: color $animation-time;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    transform: scaleX(0);
    background-color: currentColor;
    transition: transform $animation-time;
  }

  &:hover,
  &:focus {
    &:after {
      transform: scale(0.999);
    }
  }

  &:active {
    &:after {
      transform: scale(0.95);
    }
  }
}

.linkPrimary {
  @extend %basicLinkStyles;
  color: $link-primary-color;

  &:after {
    bottom: 0;
    height: 1px;
  }

  &:hover,
  &:focus {
    color: $link-primary-hover-color;
  }

  &:active {
    color: $link-primary-active-color;
  }
}

.linkSecondary {
  @extend %basicLinkStyles;
  color: $link-secondary-color;
  font-size: $font-size10;
  font-weight: 700;
  text-transform: uppercase;

  &:after {
    bottom: -3px;
    height: 2px;
  }

  &:hover,
  &:focus {
    color: $link-secondary-hover-color;
  }

  &:active {
    color: $link-secondary-active-color;
  }
}

.linkTertiary {
  @extend %basicLinkStyles;
  color: $link-tertiary-color;
  font-size: $font-size10;
  font-weight: 700;
  text-transform: uppercase;

  &:after {
    bottom: -5px;
    height: 2px;
  }

  &:hover,
  &:focus {
    color: $link-tertiary-hover-color;
  }

  &:active {
    color: $link-tertiary-active-color;
  }
}

.linkQuaternary {
  @extend %basicLinkStyles;
  color: $link-quaternary-color;

  &:after {
    bottom: 0;
    height: 1px;
  }

  &:hover,
  &:focus {
    color: $link-quaternary-hover-color;
  }

  &:active {
    color: $link-quaternary-active-color;
  }
}
