@import '../../styles/variables.scss';

.detailsLink {
  display: flex;
}

.figure {
  position: relative;
  width: 10px;
  height: 11px;
  margin-left: 13px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 8px;
    height: 1px;
    background-color: $color-firm1;
  }

  &:before {
    top: 3px;
    transform: rotate(45deg);
  }

  &:after {
    bottom: 2px;
    transform: rotate(-45deg);
  }
}
