@import '../../styles/mixins.scss';

%basicButtonStyles {
  @include css-lock-by-width(25, 29, $mobile-min-width, $desktop-max-width, border-radius);
  @include css-lock-by-width($font-size10, $font-size9, $mobile-min-width, $desktop-max-width);
  min-width: 160px;
  min-height: 40px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $button-color;
  font-weight: 700;
  font-size: $font-size10;
  line-height: 1;
  padding: 5px 18px;
  border: 2px solid transparent;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  letter-spacing: 0.05em;
  outline: none;
  user-select: none;
  cursor: pointer;
  font-family: inherit;
  transition: background-color $animation-time, box-shadow $animation-time;

  &:hover,
  &:focus {
    box-shadow: none;
  }

  &:active {
    border: 2px solid currentColor;
    background-color: transparent;
  }
}

.btnPrimary {
  @extend %basicButtonStyles;
  background-color: $button-primary-background-color;
  box-shadow: 0 0 0 5px rgba($button-primary-background-color, 0.2);

  &:active {
    color: $color-firm1;
  }
}

.btnSecondary {
  @extend %basicButtonStyles;
  background-color: $button-secondary-background-color;
  box-shadow: 0 0 0 5px rgba($button-secondary-background-color, 0.2);

  &:active {
    color: $color-firm9;
  }
}

.btnTertiary {
  @extend %basicButtonStyles;
  color: $color-firm4;
  background-color: $button-tertiary-background-color;
  box-shadow: 0 0 0 5px rgba($button-tertiary-background-color, 0.2);

  &:active {
    color: $color-white;
  }
}

.fullWidth {
  display: block;
  width: 100%;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.loading {
  color: transparent;
}

.spinner {
  width: 22px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
