@import '../../styles/mixins.scss';

.citySelection {
  @include content-max-width;
  text-align: center;
}

.selection {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.selectionBtn {
  @include css-lock-by-width(70, 164, $mobile-min-width, $desktop-content-max-width, width);
  @include css-lock-by-width(25, 50, $mobile-min-width, $desktop-content-max-width, margin-top);
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: none;
  background-color: rgba($color-firm4, 0.1);

  &:hover,
  &:focus {
    box-shadow: 0 0 0 2px rgba($color-firm4, 0.4);
  }

  &:active {
    box-shadow: none;
    color: $color-firm4;
  }
}

.icon {
  width: 10px;
  height: 13px;
  margin-right: 8px;
  fill: $color-firm4;
}

@media (max-width: #{$mobile-max-width}px) {
  .citySelection {
    padding: 0;
  }

  .selection {
    @include css-lock-by-width(25, 50, $mobile-min-width, $desktop-content-max-width, margin-top);
    @include css-lock-by-width(25, 50, $mobile-min-width, $desktop-content-max-width, margin-bottom);
    flex-direction: column;
    align-items: center;
  }

  .selectionBtn {
    &:first-child {
      margin-top: 0;
    }
  }
}
