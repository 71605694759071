@import '../../styles/variables.scss';

.carousel {
  :global {

    /* stylelint-disable selector-max-compound-selectors */
    /* stylelint-disable selector-max-combinators */
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      background-color: $color-firm18;
      opacity: 1;
      transition: transform $animation-time, background-color $animation-time;
    }

    .swiper-button-prev,
    .swiper-button-next {
      z-index: 2;
      transition: opacity $animation-time;
    }

    .swiper-button-disabled {
      opacity: 0;
    }

    .swiper-pagination-bullet-active {
      transform: scale(1.5);
      background-color: $color-firm1;
    }

    /* stylelint-enable selector-max-compound-selectors */
    /* stylelint-enable selector-max-combinators */
  }
}
